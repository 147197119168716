import Style from "@/enum/style";

export default {
  namespaced: true,
  state: {
    style: Style.AIR,
  },
  mutations: {
    setStyle(state: { style: Style }, style: Style) {
      state.style = style;
    },
  },
  getters: {
    getStyle(state: { style: Style }) {
      return state.style;
    },
  },
};
