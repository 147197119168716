
import {mapGetters, mapMutations} from "vuex";
import Style from "@/enum/style";

export default {
  computed: {
    ...mapGetters({
      style: 'AppearanceStore/getStyle',
    }),
  },
  methods: {
    ...mapMutations({
      setStyle: 'AppearanceStore/setStyle',
    }),
    selectFunkcio() {
      this.setStyle(Style.FUNKCIO);
    },
    selectAir() {
      this.setStyle(Style.AIR);
    },
  },
};
