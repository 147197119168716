import {createApp, watch} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import Style from "@/enum/style";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const app = createApp(App);

app.directive('for-funkcio', {
  beforeMount(el) {
    const evaluate = () => {
      if (store.getters['AppearanceStore/getStyle'] !== Style.FUNKCIO) {
        el.classList.add('style-not-available-for-element');
      } else {
        el.classList.remove('style-not-available-for-element');
      }
    };

    watch(() => store.getters['AppearanceStore/getStyle'], () => {
      evaluate();
    });

    evaluate();
  },
});

app.directive('for-air', {
  beforeMount(el) {
    const evaluate = () => {
      if (store.getters['AppearanceStore/getStyle'] !== Style.AIR) {
        el.classList.add('style-not-available-for-element');
      } else {
        el.classList.remove('style-not-available-for-element');
      }
    };

    watch(() => store.getters['AppearanceStore/getStyle'], () => {
      evaluate();
    });

    evaluate();
  }
});

app.use(store).use(router).mount('#app');
