import { createStore } from 'vuex'
import AppearanceStore from "@/store/modules/appearance-store";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    AppearanceStore,
  },
});

