import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "style-selector-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, {
      class: _normalizeClass(_ctx.style)
    }, null, 8, ["class"]),
    _createElementVNode("footer", _hoisted_1, [
      _createElementVNode("p", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.selectFunkcio && $options.selectFunkcio(...args)))
      }, "funkcio"),
      _createElementVNode("p", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.selectAir && $options.selectAir(...args)))
      }, "air")
    ])
  ], 64))
}